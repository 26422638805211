import { apiQuery_paymentChannel } from '@/resource'
import { countryCodeEnum } from '@/constants/country'

export default {
  data() {
    return {
      africaZotapaySetup: {
        6459: {
          header: 'deposit.uganda.header',
          logo: 'bankUganda',
          dataTestId: 'ugandaZotapay',
        }, // Uganda
        4917: {
          header: 'deposit.rwanda.header',
          logo: 'bankRwanda',
          dataTestId: 'rwandaZotapay',
        }, // Rwanda
        // 6996: {
        //   header: 'deposit.zambia.header',
        //   logo: 'bankZambia',
        //   dataTestId: 'zambiaZotapay',
        // }, // Zambia
        // 7031: { header: 'deposit.congo.header', logo: 'bankCongo', dataTestId: 'congoZotapay' }, // Congo
        4688: {
          header: 'deposit.cameroon.header',
          logo: 'bankCameroon',
          dataTestId: 'cameroonZotapay',
        }, // Cameroon
        // 3865: {
        //   header: 'deposit.burundi.header',
        //   logo: 'bankBurundi',
        //   dataTestId: 'burundiZotapay',
        // }, // Burundi
        4759: { header: 'deposit.kenya.header', logo: 'bankKenya', dataTestId: 'kenyaZotapay' }, // Kenya
        4611: { header: 'deposit.ghana.header', logo: 'bankGhana', dataTestId: 'ghanaZotapay' }, // Ghana
        7089: {
          header: 'deposit.tanzania.header',
          logo: 'bankTanzania',
          dataTestId: 'tanzaniaZotapay',
        }, // Tanzania
      },
      perfectMoneyCountriesList: [
        5176, // United States
        7036, // North Korea
      ],
      equalsIBTCountriesList: [
        3290, // Algeria
        3490, // Angola
        7047, // Antigua and Barbuda
        3346, // Argentina
        7049, // Anguilla
        7048, // Aland Islands
        7027, // American Samoa
        3481, // Andorra
        7050, // Aruba
        3554, // Austria
        3512, // Australia
        3588, // Bahamas
        7028, // Bangladesh
        3781, // Bolivia
        3808, // Botswana
        3636, // Brazil
        6458, // Brunei
        3701, // Belgium
        3624, // Bahrain
        3686, // Benin
        3817, // Bhutan
        3674, // Bulgaria
        3809, // Belize
        4688, // Cameroon
        4160, // Cape Verde
        7008, // Chad
        7010, // Chile
        1, // China
        7055, // Christmas Island
        7056, // Cocos (Keeling) Islands
        4186, // Colombia
        4713, // Comoros
        4221, // Costa Rica
        7097, // Curacao
        6016, // Cyprus
        4661, // Czech Republic
        7031, // Congo, Republic of(Brazzaville)
        4809, // Cook Islands
        4575, // Canada
        4540, // Djibouti
        3899, // Denmark
        3981, // Dominica
        3982, // Dominican Republic
        4070, // Ecuador
        3416, // Egypt
        5961, // El Salvador
        7032, // Equatorial Guinea
        7058, // Falkland Islands
        4127, // French Polynesia
        4126, // FAROE_ISLAND
        4137, // Fiji
        4138, // Finland
        4101, // France & Wallis and Futuna Islands
        7059, // French Guiana
        4611, // Ghana
        4254, // Guyana
        4624, // Gabon
        4183, // Gambia
        5859, // Georgia
        3915, // Germany
        4232, // Greenland
        4231, // Grenada
        7062, // Guadeloupe
        4253, // Guam
        6404, // Guatemala
        7063, // Guernsey
        4514, // Honduras
        7033, // HongKong
        3714, // Iceland
        6845, // India
        3436, // Ireland
        5860, // Japan
        7066, // Jersey
        4266, // Kazakhstan
        4759, // Kenya
        4535, // Kiribati
        4735, // Kuwait
        4546, // Krgyzstan
        4850, // Laos
        4810, // Latvia
        4838, // Lesotho
        4910, // Liechtenstein
        4897, // Lithuania
        4912, // Luxembourg
        5160, // Mauritius
        5557, // Micronesia
        5527, // Mongolia
        5605, // Moldova, Republic of
        7069, // Macao
        4978, // Madagascar
        5010, // Malawi
        4986, // Maldives
        7070, // Martinique
        5161, // Mauritania
        5619, // Mexico
        5617, // Monaco
        7073, // Montserrat
        5618, // Mozambique
        5681, // Namibia
        5751, // Nepal
        7077, // Norfolk Island
        5750, // Nauru
        4482, // Netherlands
        7075, // New Caledonia
        6647, // New Zealand
        5786, // Niger
        7076, // Niue
        5804, // Norway
        3389, // Oman
        3600, // Paraguay
        3567, // Papua New Guinea
        7079, // Pitcairn Island
        5826, // Palau
        5558, // Peru
        3716, // Poland
        5828, // Portugal
        // 3715, // Puerto Rico
        4700, // Qatar,
        7080, // Reunion
        6050, // Sao Tome and Principe
        5987, // Serbia
        6057, // Sri Lanka
        6109, // Swaziland
        6053, // Saint Lucia
        6054, // San Marino
        6025, // Saudi Arabia
        6645, // Singapore
        6084, // Slovakia
        6133, // Solomon Islands
        5696, // South Africa
        7041, // South Korea
        6121, // Suriname
        7087, // Svalbard and Mayen
        5909, // Sweden
        5932, // Switzerland
        7089, // Tanzania
        6278, // Tunisia
        6385, // Turkmenistan
        6304, // Tuvalu
        7092, // Turks and Caicos Islands
        7043, // Taiwan
        6163, // Thailand
        3959, // Timor-Leste
        3974, // Togo
        7091, // Tokelau
        6268, // Tonga
        7083, // Saint Maarten / Saint Martin
        3382, // United Arab Emirates
        6544, // Uruguay
        6565, // Uzbekistan
        7065, // Isle Of Man
        5156, // Marshall Islands
        7039, // Saint Kitts and Nevis
        6056, // Saint Vincent and the Grenadines
        6024, // Seychelles
        7081, // Saint Barthelemy
        7082, // Saint Helena, Ascension and Tristan
        7084, // Saint Pierre and Miquelon
        5511, // Samoa
        6431, // Virgin Islands
        7044, // Vatican City
        6991, // Vietnam
        6777, // Italy
        6907, // United Kingdom
      ],
      localDepositorCountriesList: [
        5015, //malaysia
      ],
      euroSepaCountriesList: [
        countryCodeEnum.AUSTRIA,
        countryCodeEnum.BULGARIA,
        countryCodeEnum.CYPRUS,
        countryCodeEnum.CZECH_REPUBLIC,
        countryCodeEnum.FINLAND,
        countryCodeEnum.FRANCE,
        countryCodeEnum.GERMANY,
        countryCodeEnum.IRELAND,
        countryCodeEnum.LATVIA,
        countryCodeEnum.LITHUANIA,
        countryCodeEnum.LUXEMBOURG,
        countryCodeEnum.NETHERLANDS,
        countryCodeEnum.POLAND,
        countryCodeEnum.PORTUGAL,
        countryCodeEnum.SLOVAKIA,
        countryCodeEnum.SWEDEN,
      ],
      mysCountryCode: 5015,
      idnCountryCode: 6877, // indonesia
      inCountryCode: 6845, // india
      ngaCountryCode: 5796, //Nigeria
      thaCountryCode: 6163,
      vnmCountryCode: 6991,
      phpCountryCode: 4131, // Philippines
      canCountryCode: 4575, // Canada
      ausCountryCode: 3512, // Australia
      krCountryCode: 7041,
      chCountryCode: 1, // china
      brlCountryCode: 3636, // Brazil
      jpyCountryCode: 5860, // Japan
      zarCountryCode: 5696, // South Africa
      qatCountryCode: 4700, // Qatar
      nlCountryCode: 4482, //	Netherlands
      uaeCountryCode: 3382, //	United Arab Emirates
    }
  },
  methods: {
    query_paymentChannel() {
      apiQuery_paymentChannel()
        .then(resp => {
          if (resp.data.code == 0) {
            if (resp.data.data == 1) this.$router.push({ path: `/deposit/thailandZotaPay/${resp.data.data}` })
          }
        })
        .catch(err => {
          this.$message({
            message: 'Fetch payment channel failed.',
            type: 'error',
          })
        })
    },
    notRestricted(type, channel = 1) {
      return this.restrictedDeposit === 0 ? true : this.checkRestricted(type, channel)
    },
    checkRestricted(type, channel) {
      let restricted = false

      this.restrictedDeposit.forEach(function (x, y) {
        if (x.paymentType === type && x.paymentChannel === channel) {
          restricted = true
        }
      })
      if (
        this.$config.restrictCreditCardCountry({
          reg: this.$store.state.common.regulator,
          countryCode: this.countryCode,
        })
      ) {
        restricted = true
      }
      return !restricted
    },
    zotaClick() {
      if (this.countryCode == this.phpCountryCode) {
        this.$router.push({ name: 'philipinessPayment' })
      } else if (this.countryCode == this.thaCountryCode) {
        this.query_paymentChannel()
      }
    },
    PaystackClick() {
      this.$router.push({ name: 'paystack' })
    },
    thailandXpayClick() {
      this.$router.push({ name: 'thailandXpay' })
    },
    vietnamXpayClick() {
      this.$router.push({ name: 'vietnamXpay' })
    },
    vietnamZotapayClick() {
      this.$router.push({ name: 'vietnamZotapay' })
    },
    malaysiaPaytrustClick() {
      this.$router.push({ name: 'malaysiaPaytrust' })
    },
    malaysiaZotapayClick() {
      this.$router.push({ name: 'malaysiaZotapay' })
    },
    malaysiaXpayClick() {
      this.$router.push({ name: 'malaysiaXpay' })
    },
    // indonesiaBankwireClick() {
    //   this.$router.push({ name: 'indonesiaBankwire' })
    // },
    indonesiaXpayClick() {
      this.$router.push({ name: 'indonesiaXpay' })
    },
    indonesiaZotapayClick() {
      this.$router.push({ name: 'indonesiaZotapay' })
    },
    apgClick() {
      this.$router.push({ name: 'apgDeposit' })
    },
    sdPayClick() {
      this.$router.push({ path: `/deposit/sdPay` })
    },
    solidpaymentClick() {
      this.$router.push({ path: `/deposit/solidpayment` })
    },
    appleSolidpayClick() {
      this.$router.push({ path: `/deposit/appleSolidpay` })
    },
    virtualPayClick() {
      this.$router.push({ name: 'virtualPayDeposit' })
    },
    worldcardClick() {
      this.$router.push({ name: 'worldcardDeposit' })
    },
    // maxisPayClick() {
    //   this.$router.push({ name: 'maxisPayDeposit' })
    // },
    bridgerPayClick() {
      this.$router.push({ name: 'bridgerPayDeposit' })
    },
    bitwalletClick() {
      this.$router.push({ name: 'bitwalletDeposit' })
    },
    dragonPhoenixClick() {
      this.$router.push({ name: 'dragonPhoenixDeposit' })
    },
    ebuyClick() {
      this.$router.push({ name: 'ebuyDeposit' })
    },
    bridgePaymentClick() {
      this.$router.push({ name: 'bridgePaymentDeposit' })
    },
    internationalClick(type) {
      const res = this.checkIBTStatusAndShowDialog()
      if (res) {
        let name = type == 1 ? 'internationalSwift' : 'internationalEqualsMoney'
        this.$router.push({ name })
      }
    },
    australiaClick() {
      this.$router.push({ name: 'bankWireAustralia' })
    },
    bpayClick() {
      this.$router.push({ name: 'bPayDeposit' })
    },
    poliClick() {
      this.$router.push({ name: 'poliPay' })
    },
    skrillClick() {
      this.$router.push({ name: 'skrillPay' })
    },
    sticPayClick() {
      this.$router.push({ name: 'sticPay' })
    },
    netellerClick() {
      this.$router.push({ name: 'neteller' })
    },
    // canadaLocalBankClick() {
    //   this.$router.push({ name: 'canadaLocalBank' })
    // },
    brokerClick() {
      this.$router.push({ name: 'brokerToBrokerTransfer' })
    },
    unionClick() {
      this.$router.push({ name: 'unionPay' })
    },
    chinaUnionClick() {
      this.$router.push({ name: 'unionPayChina' })
    },
    fasapayClick() {
      this.$router.push({ name: 'fasaPay' })
    },
    // mobileClick() {
    //   this.$router.push({ name: 'mobilePay' })
    // },
    paypalClick() {
      this.$router.push({ name: 'paypal' })
    },
    trustlyClick() {
      this.$router.push({ name: 'trustly' })
    },
    bitcoinClick() {
      this.$router.push({ name: 'bitcoin' })
    },
    usdtClick(channel) {
      this.$router.push({ path: `/deposit/usdt/${channel}` })
    },
    bitcoinCPSClick() {
      this.$router.push({ name: 'bitcoinCPS' })
    },
    omniCPSClick() {
      this.$router.push({ name: 'omniCPS' })
    },
    usdt_cpsClick() {
      this.$router.push({ name: 'usdt_cps' })
    },
    trc20_cpsClick() {
      this.$router.push({ name: 'trc20_cps' })
    },
    // astropayClick() {
    //   this.$router.push({ name: 'astropayDeposit' })
    // },
    indiaZotapayClick() {
      this.$router.push({ name: 'indiaZotapay' })
    },
    // southAfricaClick() {
    //   this.$router.push({ name: 'southAfricaPayment' })
    // },
    africaClick() {
      this.$router.push({ name: 'africaZotapay' })
    },
    perfectMoneyClick() {
      this.$router.push({ name: 'perfectMoney' })
    },
    // indiaBankTransferClick() {
    //   this.$router.push({ name: 'indiaBankTransfer' })
    // },
    nigeriaBankTransferClick() {
      this.$router.push({ name: 'nigeriaBankTransfer' })
    },
    // localDepositorClick() {
    //   this.$router.push({ name: 'localdepositor' })
    // },
    // iDealClick() {
    //   this.$router.push({ name: 'iDeal' })
    // },
    uaelocalbanktransferClick() {
      this.$router.push({ name: 'uaelocalbanktransfer' })
    },
    euroSepaClick() {
      const res = this.checkIBTStatusAndShowDialog()
      if (res) {
        this.$router.push({ name: 'euroSepa' })
      }
    },
  },
  computed: {
    countryCode() {
      return parseInt(this.$store.state.common.countryCode)
    },
    restrictedAfricaZotapay() {
      return !this.africaZotapaySetup.hasOwnProperty(this.countryCode)
    },
    restrictedDeposit() {
      return this.$store.state.payment.restrictedDeposit
    },
    excludePerfectMoneyCountries() {
      return !this.perfectMoneyCountriesList.includes(this.countryCode)
    },
    excludeEqualsIBTCountries() {
      return this.equalsIBTCountriesList.includes(this.countryCode)
    },
    localDepositorCountries() {
      return this.localDepositorCountriesList.includes(this.countryCode)
    },
    restrictedChina() {
      return this.countryCode != this.chCountryCode
    },
    isShowEuroSepa() {
      return this.euroSepaCountriesList.includes(this.countryCode)
    },
  },
}
